import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import EntityCharts from "@/components/EntityCharts";
import CalendarMixin from "@/mixins/Calendar";
import ReviewManagementMixin from "@/mixins/ReviewManagement";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import { getEntityCellsConfig } from "@/utils/enums";
import HasTokens from "@/components/HasTokensReviewManagement.vue";
import ScriptsTable from "@/components/ReviewManagement/ScriptsTable.vue";
import AppTabs from "@/components/AppTabs.vue";
import CategoriesTable from "@/components/ReviewManagement/CategoriesTable.vue";
import ReviewsNewTable from "@/components/ReviewManagement/ReviewsNewTable.vue";
import ReviewsArchiveTable from "@/components/ReviewManagement/ReviewsArchiveTable.vue";
const tableComponents = {
  "reviews-new": ReviewsNewTable,
  "reviews-archive": ReviewsArchiveTable,
  categories: CategoriesTable
};
export default {
  mixins: [CalendarMixin, ReviewManagementMixin],
  meta: {
    title: "Обзор показателей"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("reviewManagementCalendar"));
    const tabs_tables = [{
      id: "reviews-new",
      title: "Отзывы без ответа"
    }, {
      id: "reviews-archive",
      title: "Обработанные отзывы"
    }, {
      id: "categories",
      title: "Статистика категорий"
    }];
    if (localStorageCalendar) {
      this.$store.dispatch("reviewManagement/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.reviewManagement.calendar,
      tabs_tables,
      tabs_tables_model: tabs_tables[0],
      totalScripts: 0
    };
  },
  methods: {
    async loadOverview() {
      // let dynamic = null;
      let total = null;
      if (this.marketplace.title === "Wildberries") {
        // dynamic = await this.$store.dispatch(
        //     "reviewManagement/getWbHighlightsByDays",
        //     this.reportSettings
        // );
        total = await this.$store.dispatch("reviewManagement/getWbHighlights", this.reportSettings);
      }
      if (this.marketplace.title === "OZON") {
        // dynamic = await this.$store.dispatch(
        //     "reviewManagement/getOzHighlightsByDays",
        //     this.reportSettings
        // );
        total = await this.$store.dispatch("reviewManagement/getOzHighlights", this.reportSettings);
      }
      let res = {};
      const keys = Object.keys(total);
      keys.forEach(item => {
        res = {
          ...res,
          [item]: {
            total: Number(total[item]).toFixed(2),
            dynamic: []
          }
        };
      });
      // const keys = Object.keys(dynamic?.data[0]);
      // total?.data.forEach((item, index) => {
      //     res = {
      //         ...res,
      //         [keys[index + 2]]: {
      //             total: Number(item.value).toFixed(0),
      //             dynamic: dynamic.data.map((i) => {
      //                 return i[`${item.id}`];
      //             }),
      //         },
      //     };
      // });
      return res;
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    addScript() {
      // const limit = this.$access.getLimit("reviewManagement");
      // if (this.totalScripts >= limit) {
      //     this.$modal.open(
      //         () =>
      //             import(
      //                 "@/components/modals/access/ModalLimitReachedTemplate"
      //             ),
      //         {
      //             alert: "Превышен лимит на вашем тарифном плане",
      //             title: "Исчерпан лимит сценариев. Для увеличения лимита необходимо перейти на старший тариф",
      //         }
      //     );
      // } else {
      this.$router.push({
        name: "ReviewManagementScript"
      });
      // }
    },
    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  computed: {
    cells() {
      return this.$store.state.reviewManagement.marketplace.title === "Wildberries" ? getEntityCellsConfig("reviewManagementWb") : getEntityCellsConfig("reviewManagementOzon");
    },
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      if (!tokens.length) {
        return null;
      }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        date: dates[0],
        date2: dates[1],
        tokens,
        page_size: 10
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("reviewManagement/updateCalendar", this.calendar);
      localStorage.setItem("reviewManagementCalendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    EntityCharts,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    HasTokens,
    DataLoading,
    ScriptsTable,
    AppTabs
  }
};