var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrapper"
  }, [_vm.label ? _c('Tooltip', {
    attrs: {
      "position": "top left"
    }
  }, [_vm._v(" По умолчанию товары и продажи FBS отключены в отчетах. Данные по остаткам и продажам FBS как правило имеют сильные погрешности и аномалии, т.к. поставщик может выставлять остатки произвольно в любой момент. Если включить опцию, то в отчеты будут добавлены данные FBS. ")]) : _vm._e(), _vm.label ? _c('label', {
    staticClass: "label",
    attrs: {
      "for": _vm.uid
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data,
      expression: "data"
    }],
    attrs: {
      "type": "checkbox",
      "id": _vm.uid,
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": Array.isArray(_vm.data) ? _vm._i(_vm.data, null) > -1 : _vm.data
    },
    on: {
      "click": _vm.confirmChangeCheck,
      "change": function ($event) {
        var $$a = _vm.data,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.data = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.data = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.data = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "content",
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "for": _vm.uid
    }
  }, [_vm._v(" Toggle ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };