var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Подключение магазина для работы с отзывами",
      "width": 1000,
      "actions": _vm.actions,
      "advanced": ""
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('span', {
          staticClass: "modal-right__title"
        }, [_vm._v("Инструкция по подключению")]), _c('p', {
          staticClass: "modal-right__text"
        }, [_vm._v(" Для подключения и управления отзывами вашего магазина требуется API токен с методом "), _c('b', [_vm._v("Вопросы и отзывы")]), _vm._v(". Обратите внимание: не включайте опцию “Только на чтение”, если вы включите данную опцию - наша система не сможет публиковать созданные ответы. "), _c('br'), _c('br'), _vm._v(" Скопируйте готовый токен или создайте новый в разделе "), _c('br'), _vm._v(" \""), _c('a', {
          staticClass: "modal-right__link",
          attrs: {
            "href": "https://seller.wildberries.ru/supplier-settings/access-to-api",
            "target": "_blank"
          }
        }, [_vm._v(" Настройки - Доступ к новому API ")]), _vm._v(" \" вашего кабинета поставщика WB ")]), _c('img', {
          staticClass: "modal-right__img",
          attrs: {
            "src": require('@/assets/images/review-management/review-management-token.png'),
            "alt": "New Token"
          }
        }), _c('a', {
          staticClass: "modal-right__link",
          attrs: {
            "href": "https://www.help.salesfinder.ru/reviews_connection",
            "target": "_blank"
          }
        }, [_vm._v(" Подробная инструкция ")])];
      },
      proxy: true
    }])
  }, [_c('ui-form', [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название магазина")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.name,
      expression: "new_connection.name"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например: Мой магазина косметики",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_connection.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Маркетплейс")]), _c('app-select', {
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": "",
      "disabled": !!_vm.mp
    },
    model: {
      value: _vm.new_connection.datasource,
      callback: function ($$v) {
        _vm.$set(_vm.new_connection, "datasource", $$v);
      },
      expression: "new_connection.datasource"
    }
  })], 1), _c('div', {
    staticClass: "modal-item",
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_c('span', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Токен API (Метод - Вопросы и отзывы)")]), _c('div', {
    staticClass: "spacer"
  }), [_c('fade-transition', [!_vm.validApiKey && _vm.mayError ? _c('div', {
    staticClass: "input-error"
  }, [_vm._v(" Некорректный формат токена, ключ должен содержать " + _vm._s(_vm.tokenLength) + " символов ")]) : _vm._e()]), _vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img",
    attrs: {
      "src": require(`@/assets/images/icons/success.svg`)
    }
  }) : _vm._e(), !_vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img img-error",
    attrs: {
      "src": require(`@/assets/images/icons/close.svg`)
    }
  }) : _vm._e()], _c('Tooltip', {
    staticClass: "tooltip",
    attrs: {
      "position": "top left"
    }
  }, [_vm._v(" Обратите внимание на правильный формат токена! "), _c('br'), _vm._v(" Для Wildberries: нужно использовать ключ с методом Вопросы и отзывы, опцию “Только на чтение” необходимо оставить пустой. Без прав на чтение, наша система не сможет публиковать созданные ответы. ")])], 2)]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.apikey,
      expression: "new_connection.apikey"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": _vm.tokenPlaceholder,
      "disabled": _vm.new_connection.datasource.id === 0
    },
    domProps: {
      "value": _vm.new_connection.apikey
    },
    on: {
      "blur": _vm.onBlurApiKey,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "apikey", $event.target.value);
      }
    }
  })]), _vm.new_connection.datasource.id === 'ozon' ? _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Client ID")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.client_id,
      expression: "new_connection.client_id"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например: 157984",
      "disabled": _vm.new_connection.datasource.id === 0
    },
    domProps: {
      "value": _vm.new_connection.client_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "client_id", $event.target.value);
      }
    }
  })]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };