import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        }), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.reviewManagement.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 942441723)
  }, [_vm._v(" Мои отзывы - Обзор показателей ")]), _vm.reportSettings ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.$store.state.reviewManagement.calendar,
      "noDynamic": true
    }
  }) : _vm._e(), _c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('button', {
          staticClass: "btn-outline btn-table",
          staticStyle: {
            "width": "150px"
          },
          on: {
            "click": _vm.addScript
          }
        }, [_vm._v(" + Добавить новый ")])];
      },
      proxy: true
    }], null, false, 3896306423)
  }, [_vm._v(" Автоматические сценарии ответов ")]), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings ? _c('scripts-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "configurable": true
    },
    on: {
      "updateTotal": _vm.updateTotal,
      "addScript": _vm.addScript
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-scripts btn-table",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'ReviewManagementScripts'
        });
      }
    }
  }, [_vm._v(" Все сценарии "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })]), _c('main-title', {
    staticClass: "mt32"
  }, [_vm._v(" Анализ и работа с отзывами ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }), _c('div', {
    staticClass: "mt32"
  }, [_vm.tabs_tables_model && _vm.reportSettings ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: _vm.tableKey,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "selectable": _vm.tabs_tables_model.id === 'reviews-new' || _vm.tabs_tables_model.id === 'reviews-archive',
      "paginatable": false
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-table",
    on: {
      "click": function ($event) {
        return _vm.$router.push(`review-management-${_vm.tabs_tables_model.id}`);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tabs_tables_model.id === "categories" ? "Все категории" : "Все отзывы") + " "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })])], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };