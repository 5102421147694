var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "scripts",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "item-id": "id",
      "loaderConfig": _vm.reportSettings,
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable,
      "no-data": "У вас пока нет созданных сценариев для ответов"
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        item
      }) {
        return [_c('switch-input', {
          attrs: {
            "confirmationOnTurnOn": true
          },
          on: {
            "willChange": data => _vm.changeStatus(item.id, data),
            "input": function ($event) {
              return _vm.changeStatus(item.id, false);
            }
          },
          model: {
            value: item.is_on,
            callback: function ($$v) {
              _vm.$set(item, "is_on", $$v);
            },
            expression: "item.is_on"
          }
        })];
      }
    }, {
      key: "approval_required",
      fn: function ({
        item
      }) {
        return [item.approval_required ? _c('span', [_vm._v("Ручная")]) : _c('span', [_vm._v("Авто")])];
      }
    }])
  }, 'data-table', _vm.$attrs, false)), _c('modal-remove-script', {
    ref: "modalRemoveScript"
  }), _c('modal-turn-on-confirm', {
    ref: "modalTurnOnConfirm"
  }), _vm.total == 0 ? _c('button', {
    staticClass: "btn-outline btn-table add-script-btn",
    staticStyle: {
      "width": "200px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('addScript');
      }
    }
  }, [_vm._v(" Создать первый сценарий ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };