import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      let result = await this.$store.dispatch("reviewManagement/getCategories", {
        ...query,
        ...this.reportSettings
      });
      //console.log(result);
      result = {
        items: [...result.data],
        total: result.total,
        success: result.success,
        message: result.message
      };
      return result;
    }
  },
  computed: {
    columns() {
      return [{
        title: "Категория",
        show: true,
        name: "name",
        type: "text",
        width: 160,
        sort: false
      }, {
        title: "Отзывов",
        show: true,
        name: "feedback_count",
        type: "text",
        width: 100
      }, {
        title: "Средняя оценка",
        show: true,
        name: "valuation",
        type: "text",
        width: 160
      }];
    }
  }
};