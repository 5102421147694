var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v(" Аналитика и автоматические ответы на отзывы покупателей ")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": _vm.img
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" Подключите ваш магазин Wildberries к SalesFinder для анализа отзывов и автоматических ответов по настроенным сценариям. "), _c('br'), _c('br'), _vm._v(" Экономьте время и повышайте репутацию вашего магазина! ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateConnection
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Подключить магазин ")]), _c('modal-add-connection', {
    ref: "modalAddList",
    on: {
      "complete": _vm.onAdded
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };