import Tooltip from "@/components/Tooltip";
export default {
  components: {
    Tooltip
  },
  props: {
    value: {
      type: [Boolean, Number],
      required: true
    },
    label: {
      type: String,
      default: null
    },
    confirmationOnTurnOff: {
      type: Boolean,
      default: false
    },
    confirmationOnTurnOn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: this.value
    };
  },
  methods: {
    confirmChangeCheck(e) {
      if (this.confirmationOnTurnOff && this.data) {
        e.preventDefault();
        this.$emit("willChange", {
          value: this.data,
          confirm: () => {
            this.confirmChange();
          }
        });
      } else if (this.confirmationOnTurnOn && !this.data) {
        e.preventDefault();
        this.$emit("willChange", {
          value: !this.data,
          confirm: () => {
            this.confirmChange();
          }
        });
      } else {
        this.confirmChange();
      }
    },
    confirmChange() {
      this.data = !this.data;
    }
  },
  computed: {
    uid() {
      return this._uid;
    }
  },
  watch: {
    value(val, oldVal) {
      if (oldVal !== val) {
        this.data = val;
      }
    },
    data(val, oldVal) {
      if (oldVal !== val) {
        this.$emit("input", val);
      }
    }
  }
};